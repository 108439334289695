import React from "react"
import classNames from "classnames"

import "./ProductImage.css"
import { useProductImages } from "../hooks/useProductImages"

export const ProductImage = ({ imageName, alt = "", className, ...props }) => {
  const data = useProductImages()

  const image = data.find(node => node.base === imageName)

  if (!image) throw new Error(`Image ${imageName} not found!`)

  const classes = classNames(["product-image", className])

  return (
    <img
      className={classes}
      src={image.publicURL}
      alt={alt}
      role="presentational"
    />
  )
}
