import React, { useMemo } from "react"
import classNames from "classnames"

import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "./products.css"
import { ProductImage } from "../../components/ProductImage"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { HeaderRight, HeaderVariants } from "../../components/header"
import CardsSlider from "../../components/CardsSlider/CardsSlider"
import { useProductImages } from "../../hooks/useProductImages"
import { Button } from "../../components/Button"

const rectangleStyles = {
  proworks: {
    top: 0,
    left: "69px",
  },
  totalmetrics: {
    transform: "rotate(90deg)",
    top: "0",
    left: "calc(50vw - 150px)",
  },
  plaud: {
    transform: "rotate(330deg)",
    top: "0",
    left: "0",
  },
  polleasy: {
    top: "0",
    left: "289px",
  },
}

const gridStyles = {
  prowoks: [
    {
      top: "192px",
      right: "275px",
    },
    {
      top: "calc(100vh - (133px / 2))",
      right: "calc(-1 * 112px / 2)",
    },
  ],
  totalmetrics: [
    {
      top: "192px",
      right: "calc(50vw - (133px / 2))",
    },
    {
      top: "calc(100vh - (133px / 2))",
      right: "116px",
    },
  ],
  plaud: [
    {
      top: "192px",
      right: "calc(50vw - 213px)",
    },
    {
      top: "calc(100vh - 132px)",
      right: "calc(-1 * 112px / 2)",
    },
  ],
  polleasy: [
    {
      top: "192px",
      right: "calc(50vw - (133px / 2))",
    },
    {
      top: "calc(100vh - (133px / 2))",
      right: "calc(-1 * 112px / 2)",
    },
  ],
}

const ProductAccordionItem = ({
  name,
  description,
  link,
  active,
  activate,
}) => (
  <div
    className={classNames(
      "ProductList__item",
      active && "ProductList__item--active"
    )}
  >
    <h5
      role="button"
      onClick={activate}
      tabIndex={2}
      className="ProductList__item-name"
    >
      {name}
    </h5>
    <p className="ProductList__item-description">{description}</p>
    <div className="ProductList__item-link-wrapper">
      <Button className="ProductList__item-link" tabIndex={1} to={link}>
        case study
      </Button>
    </div>
  </div>
)

const ProductAccordion = ({
  products,
  activeProduct,
  onChangeActiveProduct,
}) => (
  <div className="ProductList">
    {products.map(product => (
      <ProductAccordionItem
        active={activeProduct === product.id}
        activate={() => onChangeActiveProduct(product.id)}
        name={product.name}
        description={product.description}
        link={`/case-studies/${product.id}`}
      />
    ))}
  </div>
)

const useProductList = () => {
  const data = useStaticQuery(graphql`
    query {
      productList: allFile(filter: { sourceInstanceName: { eq: "products" } }) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              id
              image
              name
              order
              description
              color
            }
          }
        }
      }
    }
  `)
  return data.productList.nodes
    .map(node => node.childMarkdownRemark.frontmatter)
    .sort((productA, productB) => productA.order - productB.order)
}

const ProjectsPage = () => {
  const products = useProductList()
  const [activeProduct, setActiveProduct] = React.useState(products[0].id)
  const activeProductImage = useMemo(
    () => products.find(product => product.id === activeProduct).image,
    [activeProduct]
  )
  const activeProductColor = useMemo(
    () => `#${products.find(product => product.id === activeProduct).color}`,
    [activeProduct]
  )
  const activeRectangleStyle = rectangleStyles[activeProduct]
  const [gridOneStyle, gridTwoStyle] = gridStyles[activeProduct]
  const productImages = useProductImages()
  const [websites, websiteImageLinks, backgroundColors] = useMemo(() => {
    const websites = products.map(product => ({
      title: product.name,
      description: product.description,
      link: `/case-studies/${product.id}`,
      image: product.image,
      color: `#${product.color}`,
    }))
    const websiteImageLinks = websites.map(
      ({ image }) => productImages.find(node => node.base === image).publicURL
    )
    const backgroundColors = websites.map(({ color }) => color)
    return [websites, websiteImageLinks, backgroundColors]
  }, [products])
  return (
    <Layout pageTitle="Products" noFooter>
      <SEO title="Projects" />
      <div className="products-container container header-fixed-adjust">
        <div className="two-paned-view">
          <div className="left-pane">
            <ProductAccordion
              activeProduct={activeProduct}
              onChangeActiveProduct={setActiveProduct}
              products={products}
            />
          </div>
          <div className="right-pane">
            <div className="products-page-nav__wrapper">
              <div className="container products-page-nav__container">
                <HeaderRight
                  pageTitle="Products"
                  variant={HeaderVariants.light}
                />
              </div>
            </div>
            <div
              className="ProductSlider"
              style={{
                backgroundColor: activeProductColor,
              }}
            >
              <div className="ProductImageContainer">
                <span
                  className="background-rectangle background-rectangle--one"
                  style={activeRectangleStyle}
                />
                <span className="background-rectangle background-rectangle--two" />
                <span
                  className="background-grid background-grid--one"
                  style={gridOneStyle}
                />
                <span
                  className="background-grid background-grid--two"
                  style={gridTwoStyle}
                />
                <SwitchTransition>
                  <CSSTransition
                    key={activeProduct}
                    classNames={"product-slide"}
                    addEndListener={(node, done) =>
                      node.addEventListener("transitionend", done, false)
                    }
                  >
                    {/*<div>*/}
                    <ProductImage imageName={activeProductImage} />
                    {/*</div>*/}
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
          </div>
        </div>
        <CardsSlider
          websites={websites}
          backgroundColors={backgroundColors}
          websiteImageLinks={websiteImageLinks}
        />
        <div className="solution-links">
          <Link
            to="/solutions/projects"
            className="solution-links__item js-focus-visible"
          >
            PROJECTS
          </Link>
          {/*<Link*/}
          {/*  to="/solutions/websites"*/}
          {/*  className="solution-links__item js-focus-visible"*/}
          {/*>*/}
          {/*  WEBSITES*/}
          {/*</Link>*/}
        </div>
      </div>
    </Layout>
  )
}

export default ProjectsPage
