import { graphql, useStaticQuery } from "gatsby"

export function useProductImages() {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "solutionImages" } }) {
        nodes {
          base
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return data.allFile.nodes
}
